import { filter } from "lodash";
import { ModuleType, Project } from "../../types";

export const moduleInstancesByType = ({
  project,
  moduleType,
}: {
  project: Project;
  moduleType: ModuleType;
}) =>
  filter(project.modules, (module) => {
    return (
      module.moduleDescriptor.moduleType.toString() === ModuleType[moduleType]
    );
  });
