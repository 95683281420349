import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import { useGetProjects } from "../../hooks/useProjects";
import { useHistory } from "react-router-dom";
import { Layout } from "../common/Layout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectCard: {
      height: 100,
      padding: 10,
    },
    newProjectButton: {
      height: 50,
      fontSize: 14,
      fontWeight: 600,
      padding: 15,
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export function Dashboard() {
  const classes = useStyles();
  const history = useHistory();
  const { loading, error, data } = useGetProjects();

  if (loading) {
    return <div>Loading</div>;
  }

  return (
    <Layout selectedTab={0} title="Dashboard">
      <Grid container spacing={2}>
        {data.projects.map((project: any) => (
          <Grid item xs={6}>
            <Paper
              className={classes.projectCard}
              onClick={() => {
                history.push(`projects/${project.id}`);
              }}
            >
              {project.name}
            </Paper>
          </Grid>
        ))}

        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Paper
            className={classes.newProjectButton}
            onClick={() => {
              history.push(`new-project`);
            }}
          >
            NEW PROJECT
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}
