import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Icon, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectCard: {
      height: 100,
      padding: 10,
    },
  })
);

export function ProjectTypeCard({
  projectTypeId,
  projectTypeName,
  history,
}: {
  projectTypeId: string;
  projectTypeName: string;
  history: any;
}) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.projectCard}
      onClick={() => {
        history.push(`new-project-details?projectType=${projectTypeId}`);
      }}
    >
      <Grid container style={{ textAlign: "center" }} spacing={1}>
        <Grid item xs={12}>
          <Icon fontSize="large">add</Icon>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">{projectTypeName}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
