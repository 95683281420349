import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export function EditFieldValueDialog({
  open = true,
  handleCancel,
  handleUpdateFieldValue,
  fieldId,
  fieldName = "field name",
  initValue = "",
}: {
  open: boolean;
  handleCancel: () => void;
  handleUpdateFieldValue: ({
    fieldId,
    fieldValue,
  }: {
    fieldId: string;
    fieldValue: string;
  }) => void;
  fieldId: string;
  fieldName: string;
  initValue: string;
}) {
  const [value, setValue] = useState(initValue);
  const onChangeTextField = (event: any) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Field Value</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          label={fieldName}
          fullWidth
          onChange={onChangeTextField}
          value={value}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleUpdateFieldValue({ fieldId, fieldValue: value });
          }}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
