import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography, Button, Grid } from "@material-ui/core";
import { useGlobalStyles } from "../../hooks/useGlobalStyles";
import {
  useFindDocuments,
  useCreateDocument,
  FIND_DOCUMENTS,
} from "../../hooks/useDocument";
import { useFindDataTable } from "../../hooks/useDataTables";

import { DocumentCard } from "../common/DocumentCard";
import { Layout } from "../common/Layout";

export function DataTableEditor() {
  const { projectId, dataTableId } = useParams();
  const history = useHistory();
  const classes = useGlobalStyles();
  const [createDocument] = useCreateDocument();

  const { loading, error, data } = useFindDocuments({
    dataTableId,
  });

  const { data: dataTableData } = useFindDataTable({
    dataTableId,
  });

  if (!data || !dataTableData) {
    console.log({ data });
    return <div>Loading...</div>;
  }

  const dataTableName = dataTableData?.dataTable?.name;

  const onClickDocument = (documentId: string) => {
    return () => {
      history.push(
        `/projects/${projectId}/dataTables/${dataTableId}/documents/${documentId}`
      );
    };
  };

  const onClickAddDocument = () => {
    createDocument({
      variables: {
        document: {
          projectId,
          dataTableId,
          fieldValues: [],
        },
      },
      refetchQueries: [
        {
          query: FIND_DOCUMENTS,
          variables: {
            documentQuery: {
              dataTableId,
            },
          },
        },
      ],
    }).then((response) => {
      console.log("created");
      console.log({ response });
      const docId = response.data.createDocument.id;
      console.log({ docId });
      history.replace(
        `/projects/${projectId}/datatables/${dataTableId}/documents/${docId}`
      );
    });
  };

  return (
    <Layout
      selectedTab={0}
      title={`DataTable: ${dataTableName}`}
      onClickBuild={() => {
        history.replace(`/projects/${projectId}`);
      }}
    >
      <div style={{ overflow: "auto", height: 1000 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data.documents.results.map((doc: any) => (
              <DocumentCard doc={doc} onClick={onClickDocument(doc.id)} />
            ))}
            {data.documents.results.length === 0 && (
              <Typography>No documents found</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button onClick={onClickAddDocument}>Add Document</Button>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}
