import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ModuleDescriptor } from "../../types";

export function AddModuleDialog({
  open = true,
  moduleDescriptor,
  setModuleIntanceName,
  handleCancel,
  handleAdd,
}: {
  open: boolean;
  moduleDescriptor: ModuleDescriptor;
  setModuleIntanceName: (moduleInstanceName: string) => void;
  handleCancel: () => void;
  handleAdd: () => void;
}) {
  function onChangeInstanceName(event: any) {
    setModuleIntanceName(event.target.value);
  }
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        Add {moduleDescriptor.name}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          label="Module Instance Name"
          fullWidth
          onChange={onChangeInstanceName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
