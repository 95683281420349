import React from "react";
import * as firebase from "firebase/app";
import "firebase/auth";

function logout() {
  firebase.analytics().logEvent("logged_out");
  firebase.auth().signOut();
  localStorage.removeItem("x-gavinio-token");
}
export const defaultUser = { loggedIn: false, email: "", logout, token: "" };
export const UserContext = React.createContext(defaultUser);

export function onAuthStateChange(setUser: (user: any) => void, client: any) {
  return firebase.auth().onAuthStateChanged((user) => {
    if (user) {
      console.log({ user });
      user.getIdToken().then((idToken) => {
        console.log({ idToken });
        localStorage.setItem("x-gavinio-token", idToken);
        setUser({
          loggedIn: true,
          token: idToken,
          logout,
        });
        // client
        //   .mutate({
        //     mutation: LOGIN_WITH_PROVIDER,
        //     variables: {
        //       provider: "google",
        //       idToken,
        //     },
        //   })
        //   .then((result: any) => {
        //     console.log(result);
        //     const jwtToken = result.data.loginWithProvider.jwtToken;
        //     console.log({ jwtToken });
        //     localStorage.setItem("x-gavinio-token", jwtToken);
        //     setUser({ loggedIn: true, email: user.email, logout, jwtToken });
        //   });
      });
    } else {
      console.log("not logged in");
      setUser({ loggedIn: false, logout });
    }
  });
}
