import { gql, useMutation, useQuery } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation createProject($createProject: CreateProjectInput!) {
    createProject(createProject: $createProject) {
      id
      name
    }
  }
`;

export function useCreateProject() {
  return useMutation(CREATE_PROJECT);
}

export const GET_PROJECTS = gql`
  query projects {
    projects {
      id
      name
      modules {
        id
        name
        moduleDescriptor {
          id
          moduleType
          name
        }
      }
    }
  }
`;

export function useGetProjects() {
  return useQuery(GET_PROJECTS);
}

export const GET_PROJECT = gql`
  query project($projectId: ID!) {
    project(projectId: $projectId) {
      id
      name
      modules {
        id
        name
        moduleDescriptor {
          id
          moduleType
          name
        }
        settings {
          name
          label
          value
        }
      }
    }
  }
`;

export function useGetProject(projectId: string) {
  return useQuery(GET_PROJECT, {
    variables: {
      projectId,
    },
  });
}

export const ADD_MODULE_TO_PROJECT = gql`
  mutation addModuleToProject($addModuleToProject: AddModuleToProjectInput!) {
    addModuleToProject(addModuleToProject: $addModuleToProject) {
      id
      name
      moduleDescriptor {
        id
        name
        moduleType
        description
      }
    }
  }
`;

export function useAddModuleToProject() {
  return useMutation(ADD_MODULE_TO_PROJECT);
}

export const DEPLOY_SERVICE = gql`
  mutation deployService($projectId: ID!, $serviceId: ID!) {
    deployService(projectId: $projectId, serviceId: $serviceId)
  }
`;

export function useDeployService() {
  return useMutation(DEPLOY_SERVICE);
}

const UPDATE_SETTINGS = gql`
  mutation updateModuleSettings(
    $projectId: ID!
    $moduleId: ID!
    $settings: [ModuleSettingInput!]!
  ) {
    updateModuleSettings(
      projectId: $projectId
      moduleId: $moduleId
      settings: $settings
    )
  }
`;

export function useUpdateModuleSettings() {
  return useMutation(UPDATE_SETTINGS);
}
