import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, FormControl, Grid, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Layout } from "../common/Layout";
import { GET_PROJECTS, useCreateProject } from "../../hooks/useProjects";
import * as QueryString from "query-string";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectCard: {
      height: 100,
      padding: 10,
    },
    newProjectButton: {
      height: 50,
      fontSize: 14,
      fontWeight: 600,
      padding: 15,
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export function NewProjectDetails({
  location: { search },
}: {
  location: { search: string };
}) {
  const params = QueryString.parse(search);
  console.log({ params });

  const projectType = params.projectType;

  const classes = useStyles();
  const history = useHistory();
  const [projectName, setProjectName] = useState("");
  const [createProject] = useCreateProject();

  const onFinish = () => {
    console.log("onFinish");
    createProject({
      variables: {
        createProject: {
          name: projectName,
          projectType,
        },
      },
      refetchQueries: [
        {
          query: GET_PROJECTS,
        },
      ],
    }).then((resp) => {
      console.log(resp);
      const projectId = resp.data.createProject.id;
      console.log({ projectId });
      history.replace(`/projects/${projectId}`);
    });
  };

  const onChangeProjectName = (event: any) => {
    setProjectName(event.target.value);
  };

  return (
    <Layout selectedTab={0} title="New Project Details">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            id="project-name"
            label="Project Name"
            value={projectName}
            variant="filled"
            onChange={onChangeProjectName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={onFinish}
            variant="contained"
            color="primary"
            disabled={projectName === ""}
          >
            Finish
          </Button>
        </Grid>
      </Grid>
    </Layout>
  );
}
