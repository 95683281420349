import { gql, useMutation, useQuery } from "@apollo/client";

export const CREATE_DOCUMENT = gql`
  mutation createDocument($document: CreateDocumentInput!) {
    createDocument(document: $document) {
      id
    }
  }
`;

export function useCreateDocument() {
  return useMutation(CREATE_DOCUMENT);
}

export const UPDATE_DOCUMENT = gql`
  mutation updateDocument($updateDocumentInput: UpdateDocumentInput!) {
    updateDocument(updateDocumentInput: $updateDocumentInput) {
      id
    }
  }
`;

export function useUpdateDocument() {
  return useMutation(UPDATE_DOCUMENT);
}

export const FIND_DOCUMENTS = gql`
  query documents($documentQuery: DocumentQueryInput!) {
    documents(documentQuery: $documentQuery) {
      results {
        id
        fields {
          fieldId
          name
          value
        }
      }
    }
  }
`;

export function useFindDocuments({ dataTableId }: { dataTableId: string }) {
  return useQuery(FIND_DOCUMENTS, {
    variables: {
      documentQuery: {
        dataTableId,
      },
    },
  });
}

export const FIND_DOCUMENT = gql`
  query document($dataTableId: ID!, $id: ID!) {
    document(dataTableId: $dataTableId, id: $id) {
      id
      fields {
        fieldId
        name
        value
      }
    }
  }
`;

export function useFindDocument({
  dataTableId,
  id,
}: {
  dataTableId: string;
  id: string;
}) {
  return useQuery(FIND_DOCUMENT, {
    variables: {
      dataTableId,
      id,
    },
  });
}
