import { gql, useMutation, useQuery } from "@apollo/client";

export const ADD_DATATABLE_FIELD = gql`
  mutation addFieldToDataTable(
    $addFieldToDataTableInput: AddFieldToDataTableInput!
  ) {
    addFieldToDataTable(addFieldToDataTableInput: $addFieldToDataTableInput) {
      id
    }
  }
`;

export function useAddDataTableField() {
  return useMutation(ADD_DATATABLE_FIELD);
}

export const FIND_DATATABLE = gql`
  query dataTable($dataTableId: ID!) {
    dataTable(dataTableId: $dataTableId) {
      id
      name
      fields {
        fieldId
        name
      }
    }
  }
`;

export function useFindDataTable({ dataTableId }: { dataTableId: string }) {
  return useQuery(FIND_DATATABLE, {
    variables: {
      dataTableId,
    },
  });
}
