import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  useCreateDocument,
  FIND_DOCUMENTS,
  useFindDocument,
  useUpdateDocument,
  FIND_DOCUMENT,
} from "../../hooks/useDocument";
import { useAddDataTableField } from "../../hooks/useDataTables";
import { useGlobalStyles } from "../../hooks/useGlobalStyles";
import { AddDataTableFieldDialog } from "../dialogs/AddDataTableFieldDialog";
import { DocumentCard } from "../common/DocumentCard";
import { EditFieldValueDialog } from "../dialogs/EditFieldValueDialog";
import { find, map } from "lodash";
import { Field } from "../../types";
import { Document } from "../../types";
import { Layout } from "../common/Layout";

export function DocumentEditor() {
  const { projectId, dataTableId, documentId } = useParams();
  const history = useHistory();
  const classes = useGlobalStyles();

  const [createDocument] = useCreateDocument();
  const [updateDocument] = useUpdateDocument();
  const [addDataTableField] = useAddDataTableField();
  const [addDataTableFieldDialogOpen, setAddDataTableFieldDialogOpen] =
    useState(false);
  const [addFieldFieldName, setAddFieldFieldName] = useState("");
  const [addFieldFieldType, setAddFieldFieldType] = useState("STRING");

  const [editFieldValueDialogOpen, setEditFieldValueDialogOpen] =
    useState(false);
  const [editFieldName, setEditFieldName] = useState("");
  const [editFieldId, setEditFieldId] = useState("");
  const [editFieldValue, setEditFieldValue] = useState("");
  const [document, setDocument] = useState<Document>();

  const { loading, error, data } = useFindDocument({
    dataTableId,
    id: documentId,
  });

  useEffect(() => {
    if (data && data.document) {
      console.log(data.document);
      setDocument(data.document);
    }
  }, [data]);

  if (!data?.document) {
    return <div>Loading...</div>;
  }

  console.log({ data });

  const onClickCreateDocument = () => {
    createDocument({
      variables: {
        document: {
          projectId,
          dataTableId,
          fieldValues: [],
        },
      },
      refetchQueries: [
        {
          query: FIND_DOCUMENTS,
          variables: {
            documentQuery: {
              dataTableId,
            },
          },
        },
      ],
    }).then(() => {
      console.log("created");
      history.replace(`/projects/${projectId}/datatables/${dataTableId}`);
    });
  };

  const onClickUpdateDocument = () => {
    if (!document?.fields) {
      console.log("no data to save");
      return;
    }

    updateDocument({
      variables: {
        updateDocumentInput: {
          documentId,
          dataTableId,
          fieldValues: map(document?.fields, (field) => {
            return { fieldId: field.fieldId, value: field.value };
          }),
        },
      },
      refetchQueries: [
        {
          query: FIND_DOCUMENTS,
          variables: {
            documentQuery: {
              dataTableId,
            },
          },
        },
      ],
    }).then(() => {
      console.log("update document");
      history.replace(`/projects/${projectId}/datatables/${dataTableId}`);
    });
  };
  const onAddDataTableField = () => {
    console.log("add data table field");
    addDataTableField({
      variables: {
        addFieldToDataTableInput: {
          dataTableId,
          fieldName: addFieldFieldName,
          fieldType: addFieldFieldType,
        },
      },
      refetchQueries: [
        {
          query: FIND_DOCUMENT,
          variables: {
            dataTableId,
            id: documentId,
          },
        },
      ],
    }).then((result) => {
      console.log({ result });
      setAddDataTableFieldDialogOpen(false);
    });
  };

  const getFieldValue: ({
    fieldId,
  }: {
    fieldId: string;
  }) => string | undefined = ({ fieldId }: { fieldId: string }) => {
    const field = find(
      document?.fields || [],
      (field: { fieldId: string; value: string }) => {
        return field.fieldId === fieldId;
      }
    );

    return field?.value;
  };

  const onClickEditField = ({
    fieldId,
    fieldName,
  }: {
    fieldId: string;
    fieldName: string;
  }) => {
    setEditFieldId(fieldId);
    setEditFieldName(fieldName);
    setEditFieldValue(getFieldValue({ fieldId }) || "");
    setEditFieldValueDialogOpen(true);
  };

  const handleFieldUpdate = ({
    fieldId,
    fieldValue,
  }: {
    fieldId: string;
    fieldValue: string;
  }) => {
    const updatedFields = map(document?.fields || [], (field: Field) => {
      if (field.fieldId === fieldId) {
        return {
          ...field,
          value: fieldValue,
        };
      }

      return field;
    });

    setDocument((prevValue) => {
      return {
        ...prevValue,
        fields: updatedFields,
      };
    });

    setEditFieldValueDialogOpen(false);
  };

  return (
    <Layout
      selectedTab={0}
      title={`Document Editor`}
      onClickBuild={() => {
        history.replace(`/projects/${projectId}/datatables/${dataTableId}`);
      }}
    >
      <div style={{ overflow: "auto", height: 1000 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {document && (
              <DocumentCard
                doc={document}
                editable={true}
                onEdit={onClickEditField}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                setAddDataTableFieldDialogOpen(true);
              }}
            >
              Add Field
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Button
              onClick={() => {
                history.replace(
                  `/projects/${projectId}/datatables/${dataTableId}`
                );
              }}
            >
              Cancel
            </Button>
            {documentId && (
              <Button onClick={onClickUpdateDocument}>Save</Button>
            )}
            {!documentId && (
              <Button onClick={onClickCreateDocument}>Create</Button>
            )}
          </Grid>
          <AddDataTableFieldDialog
            open={addDataTableFieldDialogOpen}
            handleCancel={() => {
              setAddDataTableFieldDialogOpen(false);
            }}
            handleCreate={onAddDataTableField}
            fieldType={addFieldFieldType}
            setAddFieldFieldName={setAddFieldFieldName}
            setAddFieldFieldType={setAddFieldFieldType}
          />
          <EditFieldValueDialog
            open={editFieldValueDialogOpen}
            handleCancel={() => {
              setEditFieldValueDialogOpen(false);
            }}
            handleUpdateFieldValue={handleFieldUpdate}
            fieldId={editFieldId}
            fieldName={editFieldName}
            initValue={editFieldValue}
          />
        </Grid>
      </div>
    </Layout>
  );
}
