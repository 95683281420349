import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ModuleSetting } from "../../types";
import { map, reduce, size } from "lodash";
import { Button, TextField } from "@material-ui/core";

export function ServiceSettingsTable({
  settings,
  saveSettings,
}: {
  settings: Array<ModuleSetting>;
  saveSettings: (settingsMap: any) => void;
}) {
  const settingsAsMap = reduce(
    settings,
    (memo, setting) => {
      return {
        ...memo,
        [setting.name]: setting.value,
      };
    },
    {}
  );

  const [settingsMap, setSettingsMap] = useState(settingsAsMap);

  const onChangeSetting = (key: string, value: string) => {
    return (evt: any) => {
      evt.persist();
      console.log(evt);
      console.log({ key, value });
      setSettingsMap((prevValue) => {
        return {
          ...prevValue,
          [key]: evt?.target?.value,
        };
      });
    };
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Setting</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {size(settings) === 0 && (
            <TableRow>
              <TableCell component="th" scope="row">
                No settings
              </TableCell>
            </TableRow>
          )}
          {map(settingsMap, (value, key) => (
            <TableRow key={key} onClick={() => {}}>
              <TableCell component="th" scope="row">
                <h3>{key}</h3>
              </TableCell>
              <TableCell align="right">
                <TextField
                  id="filled-basic"
                  variant="filled"
                  value={value}
                  onChange={onChangeSetting(key, value)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        onClick={() => {
          saveSettings(settingsMap);
        }}
      >
        Update Settings
      </Button>
    </TableContainer>
  );
}
