import React from "react";
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Button } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import { useHistory } from "react-router-dom";
import {
  ListSubheader,
  ListItemSecondaryAction,
  Collapse,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import StorageIcon from "@material-ui/icons/Storage";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { datastoreModules, serviceModules } from "../../mocks";
import { ModuleDescriptor, Project } from "../../types";
import * as firebase from "firebase/app";
import { GET_PROJECTS } from "../../hooks/useProjects";
import { useQuery } from "@apollo/client";

export function LeftDrawer({
  open = false,
  handleDrawerClose,
  drawerWidth = 240,
  projects,
  selectedProjectId,
  openAddModuleDialog,
  openCreateProjectDialog,
}: {
  open: boolean;
  handleDrawerClose: () => any;
  drawerWidth: number;
  projects: Array<Project>;
  selectedProjectId: string;
  openAddModuleDialog: ({
    moduleDescriptor,
  }: {
    moduleDescriptor: ModuleDescriptor;
  }) => () => any;
  openCreateProjectDialog: () => any;
}) {
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
      },
      drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
      },
      listItemIcon: {
        minWidth: 35,
      },
      nested: {
        paddingLeft: theme.spacing(4),
      },
      createProjectButton: {
        width: drawerWidth - 20,
        marginLeft: 10,
        marginBottom: 10,
      },
    })
  );
  const classes = useStyles();
  const theme = useTheme();

  const [servicesExpanded, setServicesExpanded] = React.useState(true);
  const [datastoresExpanded, setDatastoresExpanded] = React.useState(true);

  const handleClickServices = () => {
    setServicesExpanded(!servicesExpanded);
  };
  const handleClickDatastores = () => {
    setDatastoresExpanded(!datastoresExpanded);
  };

  const { loading, error, data } = useQuery(GET_PROJECTS, {});

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Projects
          </ListSubheader>
        }
        dense={true}
      >
        {loading ? (
          <p>Loading</p>
        ) : (
          data.projects.map(
            ({ id, name }: { id: string; name: string }, index: number) => (
              <ListItem
                button
                key={id}
                selected={id === selectedProjectId}
                onClick={() => {
                  history.push(`/projects/${id}`);
                  firebase
                    .analytics()
                    .logEvent("project_selected", { projectId: id });
                }}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItem>
            )
          )
        )}
      </List>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        className={classes.createProjectButton}
        onClick={openCreateProjectDialog}
      >
        New Project
      </Button>
      <Divider />
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Modules
          </ListSubheader>
        }
        dense={true}
      >
        <ListItem button onClick={handleClickServices}>
          <ListItemIcon className={classes.listItemIcon}>
            <SettingsApplicationsIcon />
          </ListItemIcon>
          <ListItemText primary="Services" />
          {servicesExpanded ? <ExpandMore /> : <ExpandLess />}
        </ListItem>
        <Collapse in={servicesExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense={true}>
            {serviceModules.map((service: ModuleDescriptor) => (
              <ListItem
                button
                className={classes.nested}
                id={`service-${service.name}`}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={service.name} />
                <ListItemSecondaryAction>
                  <AddCircleOutlineIcon
                    onClick={openAddModuleDialog({ moduleDescriptor: service })}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Collapse>

        <ListItem button onClick={handleClickDatastores}>
          <ListItemIcon className={classes.listItemIcon}>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="Datastores" />
          {datastoresExpanded ? <ExpandMore /> : <ExpandLess />}
        </ListItem>
        <Collapse in={datastoresExpanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense={true}>
            {datastoreModules.map((datastore) => (
              <ListItem
                button
                className={classes.nested}
                id={`datastore-${datastore.name}`}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary={datastore.name} />
                <ListItemSecondaryAction>
                  <AddCircleOutlineIcon
                    onClick={openAddModuleDialog({
                      moduleDescriptor: datastore,
                    })}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
}
