export enum ModuleType {
  SERVICE,
  DATASTORE,
  DATATABLE,
}

export interface ModuleDescriptor {
  id: string;
  name: string;
  moduleType: ModuleType;
  description?: string;
}

export interface ModuleSetting {
  name: string;
  label: string;
  value: string;
}
export interface ModuleInstance {
  id: string;
  name: string;
  moduleDescriptor: ModuleDescriptor;
}

export interface Project {
  id: string;
  name: string;
  modules: Array<ModuleInstance>;
}

export interface Field {
  fieldId: string;
  name: string;
  value: string;
}

export interface Document {
  id?: string;
  fields: Array<Field>;
}
