import React from "react";
import {
  Card,
  CardContent,
  TableCell,
  Table,
  TableBody,
  TableRow,
  IconButton,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCell: {
      borderBottomColor: "#aaaaaa",
    },
  })
);

export const DocumentCard = ({
  doc,
  onClick = () => {},
  editable = false,
  onEdit = () => {},
}: {
  doc: any;
  onClick?: (event: any) => void;
  editable?: boolean;
  onEdit?: ({
    fieldId,
    fieldName,
  }: {
    fieldId: string;
    fieldName: string;
  }) => void;
}) => {
  const classes = useStyles();

  return (
    <Card onClick={onClick} style={{ marginBottom: 10 }}>
      <CardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tableCell}>ID</TableCell>
              <TableCell className={classes.tableCell}>{doc.id}</TableCell>
              {editable && (
                <TableCell className={classes.tableCell}></TableCell>
              )}
            </TableRow>
            {doc.fields &&
              doc.fields.map(
                ({
                  fieldId,
                  name,
                  value,
                }: {
                  fieldId: string;
                  name: string;
                  value: string;
                }) => (
                  <TableRow key={`${doc.id}-${fieldId}`}>
                    <TableCell className={classes.tableCell}>{name}</TableCell>
                    <TableCell className={classes.tableCell}>
                      {value || ""}
                    </TableCell>
                    {editable && (
                      <TableCell className={classes.tableCell}>
                        <IconButton
                          onClick={() => {
                            onEdit({ fieldId, fieldName: name });
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
