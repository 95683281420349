import React from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { LeftDrawer } from "./LeftDrawer";
import { ProjectView } from "./Project";
import { projectById, projects } from "../../mocks";
import { useParams } from "react-router-dom";
import { AddModuleDialog } from "./AddModuleDialog";
import { ModuleType, ModuleDescriptor } from "../../types";
import { LoggedInButton } from "../common/LoggedInButton";
import { CreateProjectDialog } from "../dialogs/CreateProjectDialog";
import {
  useCreateProject,
  useAddModuleToProject,
} from "../../hooks/useProjects";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    grow: {
      flexGrow: 1,
    },
    hide: {
      display: "none",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    toolbar: theme.mixins.toolbar,
  })
);

export function Creator() {
  const { projectId } = useParams();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [createProjectDialogOpen, setCreateProjectDialogOpen] = React.useState(
    false
  );
  const [createProjectName, setCreateProjectName] = React.useState("");
  const [createProject] = useCreateProject();

  const [addModuleDialogOpen, setAddModuleDialogOpen] = React.useState(false);
  const [addModuleDescriptor, setAddModuleDescriptor] = React.useState({
    id: "init",
    name: "dummy",
    moduleType: ModuleType.SERVICE,
  });
  const [addModuleInstanceName, setAddModuleInstanceName] = React.useState("");
  const [addModuleToProject] = useAddModuleToProject();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAddModuleDialogCancel = () => {
    setAddModuleDialogOpen(false);
  };

  const handleAddModuleDialogAdd = () => {
    addModuleToProject({
      variables: {
        addModuleToProject: {
          projectId,
          moduleInstanceName: addModuleInstanceName,
          moduleId: addModuleDescriptor.id,
        },
      },
      refetchQueries: ["project"],
    });
    setAddModuleDialogOpen(false);
  };

  const openAddModuleDialog = ({
    moduleDescriptor,
  }: {
    moduleDescriptor: ModuleDescriptor;
  }) => {
    return () => {
      setAddModuleDescriptor(moduleDescriptor);
      setAddModuleDialogOpen(true);
    };
  };

  const openCreateProjectDialog = () => {
    setCreateProjectDialogOpen(true);
  };

  const closeCreateProjectDialog = () => {
    setCreateProjectDialogOpen(false);
  };

  const onCreateProject = () => {
    createProject({
      variables: {
        createProject: {
          name: createProjectName,
        },
      },
      refetchQueries: ["projects"],
    });

    setCreateProjectDialogOpen(false);
  };

  return (
    <div className={classes.root}>
      <AddModuleDialog
        open={addModuleDialogOpen}
        moduleDescriptor={addModuleDescriptor}
        setModuleIntanceName={setAddModuleInstanceName}
        handleCancel={handleAddModuleDialogCancel}
        handleAdd={handleAddModuleDialogAdd}
      />
      <CreateProjectDialog
        open={createProjectDialogOpen}
        handleCancel={closeCreateProjectDialog}
        handleCreate={onCreateProject}
        setCreateProjectName={setCreateProjectName}
      />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            gavin.io
          </Typography>
          <div className={classes.grow} />
          <LoggedInButton />
        </Toolbar>
      </AppBar>
      <LeftDrawer
        handleDrawerClose={handleDrawerClose}
        open={open}
        drawerWidth={drawerWidth}
        projects={projects}
        selectedProjectId={projectId}
        openAddModuleDialog={openAddModuleDialog}
        openCreateProjectDialog={openCreateProjectDialog}
      />
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.toolbar} />
        {!!projectId && <ProjectView projectId={projectId} />}
      </main>
    </div>
  );
}
