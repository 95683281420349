export const firebaseConfig = {
  apiKey: "AIzaSyAnMYtZZrqDLg_ef-FphKhYrHHN2mf4vWo",
  authDomain: "gavin-io.firebaseapp.com",
  databaseURL: "https://gavin-io.firebaseio.com",
  projectId: "gavin-io",
  storageBucket: "gavin-io.appspot.com",
  messagingSenderId: "95455784180",
  appId: "1:95455784180:web:35b724545bd136d611c2a1",
  measurementId: "G-QMKL726GN4",
};
