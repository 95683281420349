import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Icon, Paper, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { Layout } from "../common/Layout";
import { projectTypes } from "./projectTypes";
import { ProjectTypeCard } from "./ProjectTypeCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectCard: {
      height: 100,
      padding: 10,
    },
    newProjectButton: {
      height: 50,
      fontSize: 14,
      fontWeight: 600,
      padding: 15,
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export function NewProject() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Layout selectedTab={0} title="New Project">
      <Grid container spacing={2}>
        {projectTypes.map((projectType: any) => (
          <Grid item xs={6}>
            <ProjectTypeCard
              history={history}
              projectTypeId={projectType.id}
              projectTypeName={projectType.name}
            />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}
