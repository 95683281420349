import { gql, useQuery } from "@apollo/client";

export const FIND_TEMPLATES = gql`
  query templates {
    templates {
      id
      name
    }
  }
`;

export function useFindTemplates() {
  return useQuery(FIND_TEMPLATES);
}
