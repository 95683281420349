export const projectTypes = [
  {
    id: "empty",
    name: "Empty Project",
    icon: "add",
  },
  {
    id: "rest",
    name: "REST Service",
    icon: "add",
  },
  {
    id: "crud-graphql",
    name: "GraphQL Service",
    icon: "add",
  },
];
