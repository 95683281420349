import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Icon, Paper, Typography } from "@material-ui/core";
import { find, reduce } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { Layout } from "../common/Layout";
import {
  useGetProject,
  useDeployService,
  useUpdateModuleSettings,
} from "../../hooks/useProjects";
import { SystemButton } from "../common/SystemButton";
import { ServiceSettingsTable } from "./ServiceSettingsTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectCard: {
      height: 100,
      padding: 10,
    },
    newProjectButton: {
      height: 50,
      fontSize: 14,
      fontWeight: 600,
      padding: 15,
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export function ServiceEditor() {
  const { projectId, serviceId } = useParams();
  const classes = useStyles();
  const history = useHistory();

  const { data, loading, error } = useGetProject(projectId);
  const [deploy] = useDeployService();

  const [updateModuleSettings] = useUpdateModuleSettings();
  const [deployDisabled, setDeployDisabled] = useState(false);

  const saveSettings = (settingsMap: any) => {
    const settingsArray = reduce(
      settingsMap,
      (memo: Array<Record<string, string>>, val: string, key: string) => {
        return memo.concat([
          {
            name: key,
            value: val,
          },
        ]);
      },
      []
    );

    updateModuleSettings({
      variables: {
        projectId,
        moduleId: serviceId,
        settings: settingsArray,
      },
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const service = find(data.project.modules, (module) => {
    return module.id === serviceId;
  });

  const deployService = () => {
    setDeployDisabled(true);
    console.log(`deploying service ${serviceId}`);
    deploy({
      variables: {
        projectId,
        serviceId,
      },
    }).then((response) => {
      console.log("deployed");
      console.log({ response });
    });
  };

  return (
    <Layout
      selectedTab={0}
      title={`Service: ${service.name}`}
      onClickBuild={() => {
        history.replace(`/projects/${projectId}`);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ServiceSettingsTable
            settings={service.settings}
            saveSettings={saveSettings}
          />
        </Grid>
        <Grid item xs={12}>
          <SystemButton onClick={deployService} disabled={deployDisabled}>
            Deploy
          </SystemButton>
        </Grid>
      </Grid>
    </Layout>
  );
}
