import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useGlobalStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      padding: theme.spacing(3),
    },
  })
);
