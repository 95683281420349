import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Project, ModuleType } from "../../types";
import { ProjectModulesTable } from "./ProjectModulesTable";
import { DataTableList } from "./DataTableList";
import { Typography, Button } from "@material-ui/core";
import { moduleInstancesByType } from "./moduleUtils";
import { GET_PROJECT, useAddModuleToProject } from "../../hooks/useProjects";
import { useHistory } from "react-router-dom";
import { CreateDataTableDialog } from "../dialogs/CreateDataTableDialog";

export function ProjectView({ projectId }: { projectId: string }) {
  const history = useHistory();
  const [createDataTableName, setCreateDataTableName] = useState("");
  const [
    createDataTableDialogOpen,
    setCreateDataTableDialogOpen,
  ] = React.useState(false);

  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      projectId,
    },
  });
  const [addModuleToProject] = useAddModuleToProject();

  const promptForDataTableName = () => {
    setCreateDataTableDialogOpen(true);
  };

  const createCollection = () => {
    addModuleToProject({
      variables: {
        addModuleToProject: {
          projectId,
          moduleInstanceName: createDataTableName,
          moduleId: "datatable",
        },
      },
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            projectId,
          },
        },
      ],
    }).then((response) => {
      console.log({ response });
      const datatableId = response.data.addModuleToProject.id;
      history.push(`/projects/${projectId}/datatables/${datatableId}`);
    });
  };
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Please Select Project</div>;
  }

  const project = data.project;

  return (
    <div>
      <Typography variant="h4">Project: {project.name}</Typography>
      <Typography variant="h6" paragraph>
        &nbsp;
      </Typography>

      <Typography variant="h6">DataTables</Typography>
      <ProjectModulesTable
        projectId={projectId}
        moduleType={ModuleType.DATATABLE}
        modules={moduleInstancesByType({
          project,
          moduleType: ModuleType.DATATABLE,
        })}
      />
      <Button onClick={promptForDataTableName}>Create New Data Table</Button>

      <Typography variant="h6">Services</Typography>
      <ProjectModulesTable
        projectId={projectId}
        moduleType={ModuleType.SERVICE}
        modules={moduleInstancesByType({
          project,
          moduleType: ModuleType.SERVICE,
        })}
      />
      <Typography variant="h6" paragraph>
        &nbsp;
      </Typography>
      <Typography variant="h6">Datastores</Typography>
      <ProjectModulesTable
        projectId={projectId}
        moduleType={ModuleType.DATASTORE}
        modules={moduleInstancesByType({
          project,
          moduleType: ModuleType.DATASTORE,
        })}
      />
      <CreateDataTableDialog
        open={createDataTableDialogOpen}
        handleCancel={() => {
          setCreateDataTableDialogOpen(false);
        }}
        handleCreate={createCollection}
        setCreateDataTableName={setCreateDataTableName}
      />
    </div>
  );
}
