import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export function AddDataTableFieldDialog({
  open = true,
  handleCancel,
  handleCreate,
  fieldType,
  setAddFieldFieldName,
  setAddFieldFieldType,
}: {
  open: boolean;
  handleCancel: () => void;
  handleCreate: () => void;
  fieldType: string;
  setAddFieldFieldName: (name: string) => void;
  setAddFieldFieldType: (fieldType: string) => void;
}) {
  const onChangeFieldName = (event: any) => {
    setAddFieldFieldName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add Field</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          label="Field Name"
          fullWidth
          onChange={onChangeFieldName}
          variant="outlined"
          style={{ marginBottom: 20 }}
        />
        <TextField
          select
          value={fieldType}
          label="Field Type"
          variant="outlined"
          onChange={(event) => {
            setAddFieldFieldType(event.target.value);
          }}
          fullWidth
        >
          <MenuItem value="STRING">String</MenuItem>
          <MenuItem value="INT">Integer</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary">
          Add Field
        </Button>
      </DialogActions>
    </Dialog>
  );
}
