import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Switch } from "@material-ui/core";
import { ModuleType, ModuleInstance } from "../../types";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  table: {
    //minWidth: 350,
  },
  switchTableCell: {
    width: 100,
  },
});

export function ProjectModulesTable({
  projectId,
  moduleType,
  modules,
  deployable = false,
}: {
  projectId: string;
  moduleType: ModuleType;
  modules: Array<ModuleInstance>;
  deployable?: boolean;
}) {
  const classes = useStyles();
  const history = useHistory();

  const onClickModule = ({ id, name }: { id: string; name: string }) => {
    console.log(
      `clicked module ${id} type=${moduleType} ${ModuleType[moduleType]}`
    );
    if (moduleType === ModuleType.DATATABLE) {
      history.push(`/projects/${projectId}/datatables/${id}`);
    } else if (moduleType === ModuleType.SERVICE) {
      history.push(`/projects/${projectId}/services/${id}`);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {deployable && <TableCell>Deployed</TableCell>}
            <TableCell>{ModuleType[moduleType]}</TableCell>
            <TableCell align="right">Module</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => {
                onClickModule(row);
              }}
            >
              {deployable && (
                <TableCell className={classes.switchTableCell}>
                  <Switch checked={false} color="primary" name="checkedB" />
                </TableCell>
              )}
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.moduleDescriptor.name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
