import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, Grid, Icon, Paper, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Layout } from "../common/Layout";
import { ProjectModulesTable } from "../Creator/ProjectModulesTable";
import { useQuery } from "@apollo/client";
import { ModuleType } from "../../types";
import { moduleInstancesByType } from "../Creator/moduleUtils";
import { GET_PROJECT, useAddModuleToProject } from "../../hooks/useProjects";
import { CreateDataTableDialog } from "../dialogs/CreateDataTableDialog";
import { CreateServiceDialog } from "../dialogs/CreateServiceDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    projectCard: {
      height: 100,
      padding: 10,
    },
    newProjectButton: {
      height: 50,
      fontSize: 14,
      fontWeight: 600,
      padding: 15,
      backgroundColor: theme.palette.primary.light,
    },
  })
);

export function ProjectEditor() {
  const { projectId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [addModuleToProject] = useAddModuleToProject();
  const [createDataTableName, setCreateDataTableName] = useState("");
  const [createDataTableDialogOpen, setCreateDataTableDialogOpen] =
    React.useState(false);
  const [createServiceName, setCreateServiceName] = useState("");
  const [createServiceDialogOpen, setCreateServiceDialogOpen] =
    React.useState(false);
  const [createServiceType, setCreateServiceServiceType] = useState("");

  const promptForDataTableName = () => {
    setCreateDataTableDialogOpen(true);
  };

  const createCollection = () => {
    addModuleToProject({
      variables: {
        addModuleToProject: {
          projectId,
          moduleInstanceName: createDataTableName,
          moduleId: "datatable",
        },
      },
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            projectId,
          },
        },
      ],
    }).then((response) => {
      console.log({ response });
      const datatableId = response.data.addModuleToProject.id;
      history.push(`/projects/${projectId}/datatables/${datatableId}`);
    });
  };

  const promptForServiceType = () => {
    console.log("prompt for service type");
    setCreateServiceDialogOpen(true);
  };

  const createService = () => {
    console.log(`create service ${createServiceName}`);
    addModuleToProject({
      variables: {
        addModuleToProject: {
          projectId,
          moduleInstanceName: createServiceName,
          moduleId: createServiceType,
        },
      },
      refetchQueries: [
        {
          query: GET_PROJECT,
          variables: {
            projectId,
          },
        },
      ],
    }).then((response) => {
      setCreateServiceDialogOpen(false);
    });
  };

  const { loading, error, data } = useQuery(GET_PROJECT, {
    variables: {
      projectId,
    },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Please Select Project</div>;
  }

  const project = data.project;

  return (
    <Layout
      selectedTab={0}
      title={`Project ${project.name}`}
      onClickBuild={() => {
        history.replace("/");
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">DataTables</Typography>
          <ProjectModulesTable
            projectId={projectId}
            moduleType={ModuleType.DATATABLE}
            modules={moduleInstancesByType({
              project,
              moduleType: ModuleType.DATATABLE,
            })}
          />
          <Button onClick={promptForDataTableName}>
            Create New Data Table
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Services</Typography>
          <ProjectModulesTable
            projectId={projectId}
            moduleType={ModuleType.SERVICE}
            modules={moduleInstancesByType({
              project,
              moduleType: ModuleType.SERVICE,
            })}
          />
        </Grid>
        <Button onClick={promptForServiceType}>Add Service</Button>
      </Grid>

      <CreateDataTableDialog
        open={createDataTableDialogOpen}
        handleCancel={() => {
          setCreateDataTableDialogOpen(false);
        }}
        handleCreate={createCollection}
        setCreateDataTableName={setCreateDataTableName}
      />

      <CreateServiceDialog
        open={createServiceDialogOpen}
        handleCancel={() => {
          setCreateServiceDialogOpen(false);
        }}
        handleCreate={createService}
        setCreateServiceName={setCreateServiceName}
        serviceType={createServiceType}
        setCreateServiceServiceType={setCreateServiceServiceType}
      />
    </Layout>
  );
}
