import React, { useCallback, useState } from "react";
import { Container, Grid, Button, Paper, Typography } from "@material-ui/core";
import googleLoginImage from "./btn_google_signin_dark_normal_web.png";
import * as firebase from "firebase/app";
import "firebase/auth";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100vh",
      spacing: 1,
    },
    paper: {
      marginTop: 10,
      height: 300,
    },
  })
);

function login() {
  var provider = new firebase.auth.GoogleAuthProvider();
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        resolve();
      })
      .catch((error) => reject(error));
  });
}

const HELLO_QUERY = gql`
  query hello {
    hello
  }
`;

export function Login() {
  const [error, setError] = useState("");
  const classes = useStyles();

  const { loading, error: queryError, data } = useQuery(HELLO_QUERY);

  const requestLogin = useCallback(() => {
    login().catch((error) => setError(error.code));
  }, []);

  if (loading) return <p>Loading...</p>;
  console.log({ data });

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Container maxWidth="sm">
          <Typography variant="h3">Login</Typography>
          <Paper className={classes.paper}>
            <Button
              onClick={() => {
                requestLogin();
              }}
            >
              <img src={googleLoginImage} alt="login with google" />
            </Button>
            <span>{error}</span>
          </Paper>
        </Container>
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
}
