import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MenuItem } from "@material-ui/core";

export function CreateServiceDialog({
  open = true,
  handleCancel,
  handleCreate,
  setCreateServiceName,
  serviceType,
  setCreateServiceServiceType,
}: {
  open: boolean;
  handleCancel: () => void;
  handleCreate: () => void;
  setCreateServiceName: (name: string) => void;
  serviceType: string;
  setCreateServiceServiceType: (name: string) => void;
}) {
  const onChangeCreateProjectName = (event: any) => {
    setCreateServiceName(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create Service</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="name"
          label="Service Name"
          variant="outlined"
          fullWidth
          onChange={onChangeCreateProjectName}
          style={{ marginBottom: 20 }}
        />
        <TextField
          select
          value={serviceType}
          label="Service Type"
          variant="outlined"
          onChange={(event) => {
            setCreateServiceServiceType(event.target.value);
          }}
          fullWidth
        >
          <MenuItem value="elastic-index">Search Index</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
