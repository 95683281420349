import React, { useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Creator } from "./components/Creator";
import { blue } from "@material-ui/core/colors";
import { Login } from "./components/Login";
import { UserContext, defaultUser, onAuthStateChange } from "./UserContext";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import * as firebase from "firebase/app";
import { firebaseConfig } from "./config";
import { DataTableEditor } from "./components/DataTableEditor";
import { DocumentEditor } from "./components/DocumentEditor";
import "firebase/analytics";
import { Dashboard } from "./components/Dashboard";
import { NewProject } from "./components/NewProject";
import { NewProjectDetails } from "./components/NewProjectDetails";
import { ProjectEditor } from "./components/ProjectEditor";
import { ServiceEditor } from "./components/ServiceEditor";

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: blue[500],
    },
    background: {
      default: "#112d4e",
      paper: "#1a4578",
    },
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: 24,
    },
    h2: {
      fontSize: 20,
    },
    h3: {
      fontSize: 18,
    },
  },
});

function App() {
  const [user, setUser] = useState(defaultUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(setUser, client);
    return () => {
      unsubscribe();
    };
  }, []);

  if (!user.loggedIn) {
    return (
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ApolloProvider client={client}>
            <Login />
          </ApolloProvider>
        </ThemeProvider>
      </React.StrictMode>
    );
  }

  return (
    <UserContext.Provider value={user}>
      <ApolloProvider client={client}>
        <React.StrictMode>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/olddash" exact>
                  <Creator />
                </Route>
                <Route path="/projects/:projectId" exact>
                  <ProjectEditor />
                </Route>
                <Route path="/projects/:projectId/services/:serviceId" exact>
                  <ServiceEditor />
                </Route>
                <Route
                  path="/projects/:projectId/datatables/:dataTableId"
                  exact
                >
                  <DataTableEditor />
                </Route>
                <Route
                  path="/projects/:projectId/datatables/:dataTableId/new"
                  exact
                >
                  <DocumentEditor />
                </Route>
                <Route
                  path="/projects/:projectId/datatables/:dataTableId/documents/:documentId"
                  exact
                >
                  <DocumentEditor />
                </Route>
                <Route path="/new-project" exact>
                  <NewProject />
                </Route>
                <Route
                  path="/new-project-details"
                  component={NewProjectDetails}
                  exact
                />
                <Route path="/" exact>
                  <Dashboard />
                </Route>
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        </React.StrictMode>
      </ApolloProvider>
    </UserContext.Provider>
  );
}

export default App;
