import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  BottomNavigation,
  BottomNavigationAction,
  Grid,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BuildIcon from "@material-ui/icons/Build";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      spacing: 1,
      padding: 10,
    },
    stickToBottom: {
      width: "100%",
      position: "fixed",
      bottom: 0,
    },
  })
);

export function Layout({
  selectedTab,
  title,
  children,
  onClickBuild,
}: {
  selectedTab: number;
  title: string;
  children: any;
  onClickBuild?: () => void;
}) {
  const classes = useStyles();
  const [value, setValue] = useState(selectedTab);

  return (
    <>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.stickToBottom}
      >
        <BottomNavigationAction
          label="Build"
          icon={<BuildIcon />}
          onClick={onClickBuild}
        />
        <BottomNavigationAction label="Ops" icon={<EqualizerIcon />} />
        <BottomNavigationAction label="Account" icon={<AccountCircleIcon />} />
      </BottomNavigation>
    </>
  );
}
