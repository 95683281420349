import { ModuleDescriptor, ModuleType, Project } from "./types";
import { filter, keyBy } from "lodash";

export const modules: Array<ModuleDescriptor> = [
  {
    id: "mongodb",
    name: "MongoDB DB",
    moduleType: ModuleType.DATASTORE,
    description: "MongoDB database",
  },
  {
    id: "gcs",
    name: "GCS Bucket",
    moduleType: ModuleType.DATASTORE,
    description: "Google Cloud Storage Bucket",
  },
  {
    id: "elastic-index",
    name: "ElasticSearch Index",
    moduleType: ModuleType.DATASTORE,
    description: "ElasticSearch Index",
  },
  {
    id: "auth-svc",
    name: "Auth Service",
    moduleType: ModuleType.SERVICE,
    description: "Authorization Service",
  },
  {
    id: "content-svc",
    name: "Content Service",
    moduleType: ModuleType.SERVICE,
    description: "Content Service",
  },
  {
    id: "crud-svc",
    name: "Crud Service",
    moduleType: ModuleType.SERVICE,
    description: "CRUD Service",
  },
];

const moduleById = keyBy(modules, "id");

export const serviceModules: Array<ModuleDescriptor> = filter(
  modules,
  (module) => module.moduleType === ModuleType.SERVICE
);
export const datastoreModules: Array<ModuleDescriptor> = filter(
  modules,
  (module) => module.moduleType === ModuleType.DATASTORE
);

export const projects: Array<Project> = [
  {
    id: "12312",
    name: "Pinstagram",
    modules: [
      {
        id: "auth-1",
        name: "Auth Service 1",
        moduleDescriptor: moduleById["auth-svc"],
      },
      {
        id: "mongo-1",
        name: "MongoDB",
        moduleDescriptor: moduleById["mongodb"],
      },
    ],
  },
  {
    id: "12345",
    name: "mophoto",
    modules: [
      {
        id: "auth-1",
        name: "Auth Service 1",
        moduleDescriptor: moduleById["auth-svc"],
      },
      {
        id: "mongo-1",
        name: "MongoDB",
        moduleDescriptor: moduleById["mongodb"],
      },
      {
        id: "gcs-1",
        name: "GCS Bucket",
        moduleDescriptor: moduleById["gcs"],
      },
    ],
  },
];

const projectsById = keyBy(projects, "id");

export const projectById = ({ projectId }: { projectId: string }) =>
  projectsById[projectId] || projects[0];

export const moduleInstancesByType = ({
  project,
  moduleType,
}: {
  project: Project;
  moduleType: ModuleType;
}) =>
  filter(
    project.modules,
    (module) => module.moduleDescriptor.moduleType === moduleType
  );
